<template>
  <div class="count-container">
    <div class="txt-color title"><div class="titles">{{ title }}</div> <div class="titles">{{ totitle }}</div></div>
    <div class="txt-color time"><div class="titles" >{{ timeValue[0] }} ~ {{ timeValue[1] }}</div><div class="titles">{{ totimeValue[0] }} ~ {{ totimeValue[1] }}</div></div>
    <div class="count">
      <div class="titles"  style="color:#4a90e2;">
        <countTo
          :startVal="Number(startVal)"
          :endVal="Number(endVal)"
          :duration="duration"
        ></countTo>
        <span class="suffix txt-color">{{ suffix }}</span>
      </div>
      <div class="titles" style="color: rgb(245, 165, 34);">
        <countTo
          :startVal="Number(startVal)"
          :endVal="Number(toendVal)"
          :duration="duration"
        ></countTo>
        <span class="suffix txt-color">{{ suffix }}</span>
      </div>
    </div>
    <div class="txt-color compare">
      <div>{{ remarks }}</div> 
      <!-- <div>环比<span class="" :class="Math.sign(chainRatio) && Math.sign(chainRatio) < 0 ? 'down' : 'up'"></span>{{Math.abs(chainRatio)}}%</div>
      <div>同比<span class="" :class="Math.sign(yearOnYear) && Math.sign(yearOnYear) < 0 ? 'down' : 'up'"></span>{{Math.abs(yearOnYear)}}%</div> -->
    </div>
  </div>
</template>
<script>
import countTo from "vue-count-to";
import { format, subDays } from "date-fns";
import { flow } from "@antv/g2plot";
export default {
  name: "count-to-num",
  components: { countTo },
  props: {
    title: {
      type: String,
      default: "数据统计"
    },
    totitle: {
      type: String,
      default: "数据统计"
    },
    time: {
      type: Array,
      default: () => {
        return [
          format(subDays(new Date(), 7), "yyyy-MM-dd"),
          format(new Date(), "yyyy-MM-dd")
        ];
      }
    },
    totime:{
      type: Array,
      default: () => {
        return [
          format(subDays(new Date(), 1), "yyyy-MM-dd"),
          format(new Date(), "yyyy-MM-dd")
        ];
      }
    },
    startVal: {
      type: Number,
      default: 0
    },
    endVal: {
      type: Number,
      default: 0
    },
    toendVal: {
      type: Number,
      default: 0
    },
    suffix: {
      type: String,
      default: ""
    },
    remarks: {
      type: String,
      default: "暂无说明"
    },
    yearOnYear: {},
    chainRatio: {}
  },
  data() {
    return {
      duration: 3000
    };
  },
  computed: {
    timeValue() {
      let _array = [];
      if (this.time[0] instanceof Date && this.time[1] instanceof Date) {
        _array[0] = format(this.time[0], "yyyy-MM-dd");
        _array[1] = format(this.time[1], "yyyy-MM-dd");
      } else {
        _array[0] = format(new Date(this.time[0]), "yyyy-MM-dd");
        _array[1] = format(new Date(this.time[1]), "yyyy-MM-dd");
      }
      return _array;
    },
    totimeValue() {
      let _array = [];
      if (this.totime[0] instanceof Date && this.totime[1] instanceof Date) {
        _array[0] = format(this.totime[0], "yyyy-MM-dd");
        _array[1] = format(this.totime[1], "yyyy-MM-dd");
      } else {
        _array[0] = format(new Date(this.totime[0]), "yyyy-MM-dd");
        _array[1] = format(new Date(this.totime[1]), "yyyy-MM-dd");
      }
      return _array;
    }
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>
<style scoped lang="scss">
.count-container {
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.txt-color {
  color: #6a788a;
}
.title {
  font-size: 16px;
}
.titles{
  width: 50%;
  float: left;
  text-align: center;
}
.time {
  font-size: 14px;
}
.count {
  font-size: 32px;
}
.suffix {
  font-size: 16px;
}
.compare,
.compare div {
  display: flex;
  align-items: center;
}
.compare div {
  margin-right: 10px;
}
.compare span {
  margin-left: 5px;
  margin-right: 2px;
}
.up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #2cca93;
  font-size: 0;
  line-height: 0;
}
.down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #fd6771;
  font-size: 0;
  line-height: 0;
}
</style>
